.l-header{
	text-transform: uppercase;
	color: #fff;
	font-size: 11px;
	padding: 20px 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 1;
	transition: all 0.5s;
	transform: translate3d(0,0%,0);
	z-index: 100;
	@include mq(s-tablet){
		font-size: 14px;
	}

	.hide-header &,
	.footer-playing &{
		transform: translate3d(0,-100%,0);
		opacity: 0;
	}

	&_left{
		float: left;
		font-family: $secondary-font;
		img{
			width: 57px;
		}
	}
	&_logo{
		display: inline-block;
		*{
			vertical-align: middle;
		}
		img{
			@include mq(false, mobile){
				width: 30px;
			}
		}
		span{
			font-size: 15px;
			letter-spacing: -0.14em;
			text-transform: uppercase;
			margin-left: 20px;
			@include mq(false, s-tablet){
				display: none;
			}
		}
	}

	&_right{
		float: right;
		text-align: right;
		display: inline-block;
		letter-spacing: 0.18em;
		line-height: 27px;
		@include mq(mobile){
			line-height: 56px;
		}
		*{
			display: inline-block;
			vertical-align: middle;
		}

		._share{
			@include mq(false, mobile){
				display: none;
			}
		}

		.main-site-link{
			border-right: 1px solid #643f72;
			padding-right: 10px;
			padding-left: 10px;
			display: inline-block;
			position: relative;
			@include mq(s-tablet){
				padding-right: 30px;
				padding-left: 30px;
			}
			

		}
		.langs{
			margin-left: 5px;
			margin-right: 10px;
			@include mq(s-tablet){
				margin-left: 18px;
				margin-right: 50px;
			}
			a{
				color: #b1a7b6;
				font-weight: bold;
				transition: all 0.3s;
				&.active,
				&:hover{
					color: #fff;
				}
				&:first-child{
					margin-left: 8px;
					&:after{
						content: "/";
						display: inline-block;
						color: #b1a7b6;
						padding-left: 8px
					}
				}
			}
		}
	}

}