footer{
	width: 100%;
	justify-content: space-between;
	font-size: 14px;
	color: #fff;
	z-index: 50;
	transition: all 0.3s;
	@include mq(false, s-tablet){
		text-align: center;
	}
	@include mq(s-tablet){
		transform: translate3d(0,-100%,0);
		position: absolute;
		display: flex;
	}

	.footer-playing &{
		transform: translate3d(0,0%,0);
	}


	p{
		margin: 0;
	}

	.col{
		padding: 20px 10px;
		&1{ order: 2; text-transform: uppercase; text-align: center;
			a{ letter-spacing: 0.2em; display: inline-block; margin: 0 10px 5px 10px;
				border-bottom: 1px solid transparent;
				transition: border 0.3s;
				&:hover{
					border-bottom-color: #fff;
				}
			}
		}
		&2{ order: 3; padding-right: 30px; width: 190px; }
		&3{ order: 1; padding-left: 30px; font-size: 10px; }
		@include mq(false, s-tablet){
			padding-left: 10px!important;
			padding-right: 10px!important;

		}
	}

}

	.social{
		display: inline-block;
		height: 19px;
		margin-left: 15px;
		background: url(../images/social-sprite.png) 0 center no-repeat;
		background-size: 77px auto;
		&.tw{
			width: 18px;
		}
		&.fb{
			width: 9px;
			background-position: -19px center;
		}
		&.yt{
			width: 22px;
			background-position: -28px center;
		}
		&.gp{
			width: 27px;
			background-position: -50px center;
		}
	}