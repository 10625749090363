body{
	background: #543061 url(../images/bg-large.jpg) top center no-repeat;
	&.no-scroll,
	&.show-overlay{
		overflow: hidden;
	}
}

#intro{

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%,-50%,0);
	width: 100%;
	text-align: center;
	font-family: $secondary-font;
	color: #fff;
	text-transform: uppercase;
	perspective: 800px;
	z-index: 1;
	display: none;
	padding-left: 10px;
	@include mq(s-tablet){
		padding-left: 25px;
	}

	.touchevents &{
		position: absolute;
	}

	.intro-top{
		font-size: 35px;
		margin-bottom: 30px;
		margin-top: 0;

		@include mq(s-tablet){
			font-size: 70px;
		}

	}
	.intro-bot{
		font-size: 20px;
		margin: 0;
		letter-spacing: 0.06em;
		@include mq(s-tablet){
			font-size: 30px;
		}
	}

	span{
		display: inline-block;
		transform-style: preserve-3d;
		transition: transform 1s;
	}

}

#wrap{
	text-align: center;
	position: relative;
	z-index: 2;
	overflow: hidden;
}

#bottle-wrap{

	position: relative;
	text-align: center;
	display: inline-block;
	opacity: 0;
	max-width: 860px;
	padding: 0 80px;
	margin-top: 80vh;
	width: 100%;
	max-width: 1000px;
	@include mq(mobile){
		padding: 0 150px;
	}
	@include mq(s-tablet){
		padding: 0 240px;
	}

	.bottle-alt{
		display: none;
		.oldbrowser &{
			display: block;		
		}
	}

	#svg-image-blur{
		.oldbrowser &{
			display: none;		
		}
	}


	.touchevents &{
		transform: none!important;
	}

	#svg-image-blur{
		width: 100%;
		height: auto;
	}

	.balloon{
		background: rgba(255,255,255,0);
		position: absolute;
		display: block;
		cursor: pointer;
		width: 100px;
		height: 100px;
		// margin-left: -50px;
		z-index: 50;
		transition: opacity 0.3s;

		@include mq(s-tablet){
			width: 180px;
			height: 180px;
			// margin-left: -90px;
		}

		svg{
			width: 100px;
			height: 100px;
			@include mq(s-tablet){
				width: 180px;
				height: 180px;
			}
		}

		.no-touchevents &{
			opacity: 0;
		}

		&._show{
			opacity: 1;
		}

		*{
			cursor: pointer;
		}

		.bt-play{
			width: 30px;
			height: 30px;
			background: url(../images/icon-play.png) 0 0 no-repeat;
			background-size: 100% auto;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -15px auto auto -15px;
			z-index: 5;
			opacity: 0;
			transform: scale(0.9);
			transition: all 0.3s;
		}

		svg{
			position: relative;
			z-index: 1;
			transform: scale(0.9);
			transition: all 0.3s;

		}

		circle,
		image{
			transition: all 0.3s;
		}

		.text{
			font-size: 14px;
			text-transform: uppercase;
			color: #fff;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate3d(-50%,5px,0);
			font-family: $secondary-font;
			margin-top: 20px;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
			@include mq(l-tablet){
				font-size: 22px;
			}
		}

		.no-blurred{
			opacity: 0;
			transition-delay: 0.2s;
		}

		.touchevents &{
			.no-blurred{
				opacity: 1;
			}
			.blurred{
				opacity: 0;
			}
		}

		.no-touchevents &:hover,
		.touchevents &{

			svg{
				transform: scale(1);
			}

			.no-blurred{
				opacity: 1;
			}
			.blurred{
				opacity: 0;
				transition-delay: 0.2s;
			}
			.bt-play{
				opacity: 1;
				transform: scale(1);
				transition-delay: 0.2s;
			}
			.text{
				opacity: 1;
				visibility: visible;
				transition-delay: 0.3s;
				transform: translate3d(-50%,0,0);
			}
		}

		&-1{
			top: 24%;
			// left: -8.28%;
			left: 15%;
			animation: balloon1 6s ease-in-out infinite;
		}
		&-2{
			top: 41%;
			// right: -5.36%;
			right: 0;
			// margin-right: -100px;
			// @media (min-width: 800px){
			// 	margin-right: -180px;
			// }
			animation: balloon2 6s ease-in-out infinite;
		}
		&-3{
			top: 55%;
			// left: -22.78%;
			left: 0;
			animation: balloon3 6s ease-in-out infinite;
		}
		&-4{
			top: 67%;
			right: 0;
			// right: -6.88%;
			// margin-right: -100px;
			// @media (min-width: 800px){
			// 	margin-right: -180px;
			// }
			animation: balloon4 6s ease-in-out infinite;
		}
		&-5{
			top: 77%;
			// left: -22.18%;
			left: 0;
			animation: balloon2 6s ease-in-out infinite;
		}
	}

}

#bottle{
	z-index: 10;
	position: relative;
	// @media (max-width: 800px){
	// 	display: none;
	// }
	img{
		width: 100%;
	}

	.touchenvets &{
		transform: none!important;
	}

	.oldbrowser &{
		transform: none!important;
		zoom: 1!important;
	}

	.touchevents &{ 
		.image-blurred{
			display: none;
		}
		.image-normal{
			opacity: 1!important;
		}
	}

}

#bottle-mobile{
	display: none;
// 	@media (min-width: 800px){
// 		display: none;
// 	}
}

#boxs{
	@include clearfix;
	margin-top: 100px;
	margin-bottom: 100px;
	.item{
		width: calc(100% / 3);
		float: left;
		padding: 0 15px;
		transform: translateY(30px);
		transition: all 0.6s;
		position: relative;

		@include mq(false, mobile){
			width: 100%;
			float: none;
		}

		.no-touchevents &{
			opacity: 0;
		}

		&._show{
			opacity: 1;
			transform: translateY(0px);
		}

		img{
			width: 100%;
			box-shadow: 0px 0px 16px 0px rgba(50, 50, 50, 0.25);
		}
		&2{
			margin-top: 65px;
		}
		&3{
			margin-top: 30px;
		}

		&-overlay{
			position: absolute;
			top: 0;
			left: 15px;
			right: 15px;
			bottom: 0;
			z-index: 1;
			background: rgba(#552460, 0.83);
			opacity: 0;
			transition: all 0.3s;
			display: none;
			@include mq(s-tablet){
				display: block;
			}

			span{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				color: #fff;
				text-transform: uppercase;
				width: 80%;
				line-height: 22px;
				letter-spacing: 0.28em;
				opacity: 0;
				transition: all 0.3s;
			}
			.touchevents &{
				opacity: 1;
				display: block;
			}
		}
		.no-touchevents &:hover,
		.touchevents &{
			.item-overlay,
			.item-overlay span{
				opacity: 1;
			}
			.item-overlay span{
				transition-delay: 0.2s;
			}
		}

	}

}

#video{
	height: 100vh;
	overflow: hidden;
	background: url(../images/cover-video.jpg) center no-repeat;
	background-size: cover;
	transition: all 0.6s;
	position: relative;

	.no-touchevents &{
		opacity: 0;
		transform: translateY(100px);
	}

	#video-footer{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		opacity: 0;
		transition: all 0.3s;
		.footer-playing &{
			opacity: 1;
		}
	}

	&._show{
		opacity: 1;
		transform: translateY(0px);
	}

	.bt-play{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		display: block;
		border: 5px solid #fff;
		border-radius: 50%;
		background: url(../images/icon-play2.png) center no-repeat;
		cursor: pointer;
		background-color: transparent;
		transition: all 0.3s;


		background-size: 25px auto;
		width: 80px;
		height: 80px;
		@include mq(s-tablet){
			background-size: 54px auto;
			width: 150px;
			height: 150px;			
		}

		.no-touchevents &:hover{
			background-color: rgba(#fff,0.2);
		}
		.footer-playing &{
			opacity: 0; visibility: hidden;
		}

	}

	.footer-playing &{
		background: #000;
	}

	// &.played{
	// 	.bt-play{opacity: 0; visibility: hidden}
	// }


}


#video-overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #421654;
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
	.show-overlay &{
		z-index: 500;
		opacity: 1;
		visibility: visible;
	}

	.inner{
		position: absolute;
		left: 30px;
		right: 30px;
		top: 90px;
		bottom: 90px;
		border: 1px solid #fff;
		z-index: 200;
		transition: all 0.3s;

		@include mq(false, s-tablet){
			display: none;
		}

		@media(min-width: 1025px){
			left: 102px;
			right: 102px;
			top: 84px;
			bottom: 84px;
		}

	}

	.cover,
	.cover > div{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.3s;
		background-size: cover;
	}
	.cover{
		z-index: 50;
	}
	.cover > div.active{
		opacity: 1;
	}

	.video-container{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		// transform: translate3d(-50%,-50%,0);
		opacity: 0;
		z-index: 100;
		background: #000;

		iframe{
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate3d(0,-50%,0);
		}

		video{
			width: 100%;
		}
	}

	.cover {
		.video1{ background-image: url(../images/fragole-cover.jpg); opacity: 0; }
		.video2{ background-image: url(../images/quando-esplode-cover.jpg); opacity: 0; }
		.video3{ background-image: url(../images/ogni-giorno-cover.jpg); opacity: 0; }
		.video4{ background-image: url(../images/la-sensibilita-cover.jpg); opacity: 0; }
		.video5{ background-image: url(../images/si-incontrano-cover.jpg); opacity: 0; }
		.cover-overlay{
			background: #2d1035;
			opacity: 0.41;
		}
	}

	.timeline{
		width: 1px;
		left: 60px;
		top: 108px;
		bottom: 108px;
		position: absolute;
		&:before{
			width: 100%;
			background: #fff;
			opacity: 0.25;
			display: block;
			content: "";
			position: absolute;
			top: -28px;
			bottom: -28px;
			left: 0;
		}
		> a{
			position: absolute;
			width: 1px;
			height: 1px;
			cursor: pointer;
			display: block;

			&.video1{ top: 0%; }
			&.video2{ top: 25%; }
			&.video3{ top: 50%; }
			&.video4{ top: 75%; }
			&.video5{ top: 100%; }

			&:before{
				background: transparent;
				content: "";
				display: block;
				width: 80px;
				height: 20px;
				position: absolute;
				top: -10px;
				left: -40px;
			}

			&:after{
				width: 3px;
				height: 3px;
				display: block;
				content: "";
				background: #fff;
				border-radius: 50%;
				transform: translate3d(-50%, -50%,0);
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0.5;
				transition: all 0.3s;
			}

			.text{
				position: absolute;
				right: 100%;
				top: 50%;
				transform: translate3d(0,-50%,0);
				color: #fff;
				opacity: 0.5;
				margin-right: 10px;
				margin-top: -2px;
				transition: all 0.3s;
			}

			.thumb{
				position: absolute;
				top: 50%;
				left: 100%;
				width: 86px;
				height: 86px;
				transform: translate3d(-5px,-50%,0);
				border-radius: 50%;
				margin-left: 20px;
				cursor: pointer;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				&:before{
					width: 100px;
					height: 100px;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					left: 50%;
					margin-left: -50px;
					margin-top: -50px;
					border: 2px solid #fff;
					content: "";
					display: block;
					opacity: 0.5;
				}

				.thumb-wrap{
					width: 100%;
					height: 100%;
					border-radius: 50%;
					overflow: hidden;
				}
				img{
					width: 100%;
				}
			}

			&.active{
				.thumb{
					opacity: 1;
					visibility: visible;
					transform: translate3d(0,-50%,0);
				}
			}

			&.active{

				&:after{
					width: 7px;
					height: 7px;
					opacity: 1;
				}

				.text{
					opacity: 1;
				}


			}

			.no-touchevents &:hover{

				.text{
					opacity: 1;
				}
				.thumb{
					opacity: 1;
					visibility: visible;
					transform: translate3d(0,-50%,0);
				}

			}


		}
	}

	._close{
		position: absolute;
		top: 30px;
		right: 130px;
		width: 30px;
		height: 30px;
		z-index: 300;
		border: none;
		background: none;
		cursor: pointer;
		transition: all 0.3s;

		@include mq(false, desktop){
			right: 20px;
			top: 20px;
		}

		&:before,
		&:after{
			width: 24px;
			height: 3px;
			background: #fff;
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform-origin: 50% 50%;
			border-radius: 2px;
		}
		&:before{
			transform: translateX(-50%) rotate(-45deg);
		}
		&:after{
			transform: translateX(-50%) rotate(45deg);
		}

		.no-touchevents &:hover{
			transform: rotate(180deg);
		}

	}

	.toggle{
		position: absolute;
		left: 30px;
		width: 30px;
		height: 30px;
		background: red;
		bottom: 30px;
		z-index: 100;
		background: url(../images/player-controls.png) top center no-repeat;
		background-size: 30px auto;
		cursor: pointer;

		@include mq(desktop){
			left: 115px;
		}

	}

	.progress{

		left: 80px;
		right: 30px;
		@media(min-width: 1025px){
			left: 162px;
			right: 102px;
		}

		height: 1px;
		background: rgba(#ffffff,0.14);
		position: absolute;
		bottom: 45px;
		// transform: translateX(-50%);
		z-index: 150;

		@include mq(false, s-tablet){
			display: none;
		}

		span{
			height: 1px;
			width: 0%;
			background: #fff;
			display: block;
		}
		&.animating span{
			transition: all 1s linear;
		}
	}

	.no-touchevents &.hide-controls{

		.inner{
			opacity: 0;
		}

	}

	&.playing{

		.toggle{
			background-position: center bottom;
		}

	}

	&.show-video-1 {
		.video1{ opacity: 1; }
		.timeline .video1{
			&:after{ width: 7px; height: 7px; opacity: 1; }
			.text{ opacity: 1; }
		}
	}
	&.show-video-2{
		.video2{ opacity: 1; }
		.timeline .video2{
			&:after{ width: 7px; height: 7px; opacity: 1; }
			.text{ opacity: 1; }
		}
	}
	&.show-video-3{
		.video3{ opacity: 1; }
		.timeline .video3{
			&:after{ width: 7px; height: 7px; opacity: 1; }
			.text{ opacity: 1; }
		}
	}
	&.show-video-4{
		.video4{ opacity: 1; }
		.timeline .video4{
			&:after{ width: 7px; height: 7px; opacity: 1; }
			.text{ opacity: 1; }
		}
	}
	&.show-video-5{
		.video5{ opacity: 1; }
		.timeline .video5{
			&:after{ width: 7px; height: 7px; opacity: 1; }
			.text{ opacity: 1; }
		}
	}

}


/*BALLOON 1 4*/

@keyframes balloon1 {
	0%,100%{ transform:translateY(0) rotate(-2deg);}
	50%{ transform:translateY(-10px) rotate(1deg); }
}

/* BAllOON 2 5*/

@keyframes balloon2 {
	0%,100%{ transform:translateY(0) rotate(1deg);}
	50%{ transform:translateY(-15px) rotate(-2deg); }
}


/* BAllOON 0*/

@keyframes balloon3 {
	0%,100%{ transform:translate(0, -10px) rotate(1eg);}
	50%{ transform:translate(-10px, 5px) rotate(-2deg); }
}

/* BAllOON 3*/

@keyframes balloon4 {
	0%,100%{ transform:translate(10px, -10px) rotate(-3eg);}
	50%{ transform:translate(-15px, 10px) rotate(2deg); }
}


#cookie-law{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	background: #381146;
	text-align: center;
	color: #fff;
	padding: 50px 0;
	font-size: 14px;
	line-height: 20px;
	text-transform: none;

	button{
		background: #552169;
		height: 50px;
		border-radius: 25px;
		border: none;
		padding: 0 50px;
		color: #fff;
		font-family: $font-lulo;
		font-size: 10px;
		margin-top: 20px;
		cursor: pointer;
	}

}