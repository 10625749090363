$font-size-default: 16px !default;
$font-base-path: '/assets/fonts/' !default;
$font-lulo: 'Lulo One' !default;
$font-lato: 'Lato' !default;
$primary-font: $font-lato !default;
$secondary-font: "Lulo One";

$fonts: (
    // lulo-one: (
    //     ("Lulo One", lulo-one-regular, regular, italic),
    // ),
);

@each $font in map-keys($fonts) {

    @each $font-family in map-deep-get($fonts, $font) {

        @font-face {

            font-family: '#{nth($font-family,1)}';
            font-weight: #{nth($font-family,3)};
            font-style: #{nth($font-family,4)};
            src:
                url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.eot?#iefix') format('embedded-opentype'),
                url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.woff') format('woff'),
                url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.ttf') format('truetype'),
                url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.svg##{nth($font-family,2)}') format('svg');
         }

    }
}



@font-face {
    font-family: 'Lulo One';
    src: url('../fonts/lulo/lulo-one-regular-webfont.woff2') format('woff2'),
         url('../fonts/lulo/lulo-one-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}